import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import pic1 from "../assets/images/logo.png"
import pillow from "../assets/images/about.jpg"
import edit from "../assets/images/edit.png"
import Canvas from './Canvas'

export default function Review() {
  return (
    <>
      <div className="container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: 20
        }}>

        <h1 className="logo">
          <img src={pic1} style={{ width: 200 }} />
        </h1>
        <div className="uk_signBtn">
          <button className="get-started-btn scrollto mobile_btn">UK - GBP</button>
          <button className="get-started-btn scrollto mobile_btn">UK - GBP</button>
          <button className="get-started-btn scrollto mobile_btn">UK - GBP</button>
        </div>

      </div>

      <Container >

        <div className='outer_fronbackSec'>
          <ul className='outer_frontSec'>
            <li>1 Front</li>
            <li>2 back</li>
            <li>3 REVIEW & PURCHASE</li>
            <Link to="">
              <button className="get-started-btn scrollto mobile_btn"
                style={{ marginTop: 0, marginRight: 10 }}>Add to cart</button>
            </Link>

          </ul>
        </div>


        <div className='review_middleSec'>
          <div className='reviewouter_sec'>
            <img src={pillow} />
            <div className='edit_reviewImg'>
            <img src={edit} />
            </div>
          </div>
          <div className='reviewouter_sec'>
          <img src={pillow} />
          <div className='edit_reviewImg'>
            <img src={edit} />
            </div>
          </div>
        </div>






      </Container>
    </>
  )
}
