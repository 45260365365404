import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'

export default function FirstPage() {
    return (
        <>
        <Container>
            <Row style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
            <Link to="/upload-design">
                <button>Start Making</button>
            </Link>
            </Row>
            </Container>
        </>
    )
}
