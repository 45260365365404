import React, { useRef, useState } from 'react'
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap';

import Canvas from './Canvas';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pic1 from "../assets/images/logo.png"
import account from "../assets/images/account.png"
import { Link } from 'react-router-dom';

export default function UploadDesign(props) {

    // const [file, setFile] = useState();
    // const inputRef = useRef(null);

    // const handleUploadClick = () => {

    //     inputRef.current?.click();
    // };

    // const handleFileChange = (e) => {
    //     if (!e.target.files) {
    //         return;
    //     }

    //     setFile(e.target.files[0]);


    // };



    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>

            {/* <div className="container"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: 20
                }}>

                <h1 className="logo">
                    <img src={pic1} style={{ width: 200 }} />
                </h1>
                <div className="uk_signBtn">
                    <button className="get-started-btn scrollto mobile_btn">UK - GBP</button>
                    <button className="get-started-btn scrollto mobile_btn">UK - GBP</button>
                    <button className="get-started-btn scrollto mobile_btn">UK - GBP</button>
                </div>

            </div> */}

            {/* <section className='uploadDesign_header'>
                <div className='container'>
                    <Row className='header_secview'>
                        <Col sm={12} className='img_sec'>
                            <ButtonGroup aria-label="Basic example" className='first_btn'>
                                <Button ><img src={account} /></Button>
                                <Button><img src={account} /></Button>
                                <Button ><img src={account} /></Button>
                                <Button ><img src={account} /></Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </div>
            </section> */}

            <Container >

                {/* <ul className='outer_frontSec'>
                        <li>1 Front</li>
                        <li>2 back</li>
                        <li>3 REVIEW & PURCHASE</li>
                        <Link to="/review-designnext">
                        <button class="get-started-btn scrollto mobile_btn"
                            style={{ marginTop: 0, marginRight: 10 }}>Review</button>
                        </Link>

                    </ul> */}
                <div className='outer_fronbackSec'>
                    <ul className='outer_frontSec'>
                        <li>1 Front</li>
                        <li>2 back</li>
                        <li>3 REVIEW & PURCHASE</li>
                        <Link to="/review-designnext">
                            <button className="get-started-btn scrollto mobile_btn"
                                style={{ marginTop: 0, marginRight: 10 }}>Review</button>
                        </Link>

                    </ul>
                </div>
                <Row style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                    }}>
                        <div>
                            <h4></h4>
                        </div>
                        <Canvas productData={props.productData} canvasList={props.canvasList}/>
                        <div>
                        </div>

                    </div>
                    <div className='upload_design'
                        style={{ padding: 0 }}>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <span> Upload Design</span>
                        </label>
                        <input id="file-upload" type="file" className='upload_sec' onChange={handleChange} />
                    </div>
                    <div>
                        <img src={file} className="prieveiwImg_sec" />
                    </div>

                </Row>


            </Container>

        </>
    )
}
