import React ,{useState ,useRef , useLayoutEffect} from 'react'
import { fabric } from 'fabric';
const Canvascomponent = (props) => {
  
const canvas =useRef();
useLayoutEffect(()=>{
   
  canvas.current=new fabric.Canvas(props?.id);    
  canvas.current.setWidth(props?.width);
  canvas.current.setHeight(props?.height);
  canvas.current.renderAll();
      
return()=>{
   canvas.current?.dispose()
}

},[])    
  
return (
   <div>
          <h4 style={{textAlign:"right"}}>Page {props?.count+1}</h4>
          
          <canvas id={props?.id} style={{backgroundColor:props?.count==0?"red":"black"}} />     
    </div>
  )
}

export default Canvascomponent
