import { Button } from 'bootstrap'
import React, { useRef, useState , useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom';
import Canvas from './Canvas'
import Slider from "react-slick";
import pic1 from "../assets/images/logo.png"

export default function MainComoponent(props) {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1
  };


 

  return (
    <>

      {/* <div className="container"
      style={{display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 20}}>

        <h1 className="logo">
          <img src={pic1} style={{width: 200}} />
        </h1>
        <div className="uk_signBtn">
          <button className="get-started-btn scrollto mobile_btn">UK - GBP</button>
          <button className="get-started-btn scrollto mobile_btn">UK - GBP</button>
          <button className="get-started-btn scrollto mobile_btn">UK - GBP</button>
        </div>

      </div> */}

      <Container>
        <Row style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>


          <h3 style={{
            fontSize: 30,
            fontWeight: 600,
            marginTop: 0
          }}>Create your Business Cards with easy steps</h3>

          <ul className='outer_frontSec'>
            <li>1 Front</li>
            <li>2 back</li>
            <li>3 REVIEW & PURCHASE</li>
          </ul>


          {/* <div style={{ display: 'flex', flexDirection: 'row', columnGap: 50 }}>
            <div className='inner_Pages'>
              <div style={{
                width: 400, height: 300, backgroundColor: 'white', boxShadow: "0 0 10px 0 rgb(75 89 109 / 25%)",
                borderRadius: 20
              }} ></div>
            </div>
            <div className='inner_Pages'>
              <div style={{
                width: 400, height: 300, backgroundColor: 'white', boxShadow: "0 0 10px 0 rgb(75 89 109 / 25%)",
                borderRadius: 20
              }}></div>
            </div>
          </div> */}

          <div className='uploade_mainSection'>
            <Slider {...settings}>
              <div>
                <div className='upload sec_outerDiv' >
                  <Link to="/upload-designnext">
                    <div className="placeholder full-image">
                      <span className="hint">
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72"
                        className="svg-icon icon image-placeholder"><title>0101WF_pixel-spot-illustrations</title>
                        <path d="M6.45 16.88v38.24h59.1V16.88H6.45M7.61 54V18h56.78v36H7.61m17.27-23.76a6.33 6.33 0 0 0-1.94 4.63 6.39 6.39 0 0 0 1.94 4.69 7.13 7.13 0 0 0 1.51 1.16l-2.2 3.59H49.5L39 27.16l-3.19 5.21a7.48 7.48 0 0 0-1.48-2.14 6.3 6.3 0 0 0-4.63-2 6.43 6.43 0 0 0-4.75 2m.81.81a5.35 5.35 0 0 1 7.73 0A5 5 0 0 1 35 33.71L33.57 36l-2.11-3.48L27 39.74a5.65 5.65 0 0 1-1.3-1 5.28 5.28 0 0 1-1.59-3.88A5.21 5.21 0 0 1 25.69 31m21.75 12.16H26.27l5.19-8.43 2.11 3.48L39 29.37Z" fill="#b1b9c2"></path><path d="M7.61 18v36h56.78V18H7.61m15.33 16.87a6.33 6.33 0 0 1 1.94-4.63 6.43 6.43 0 0 1 4.75-2 6.3 6.3 0 0 1 4.63 2 7.48 7.48 0 0 1 1.48 2.14L39 27.16l10.5 17.15H24.18l2.2-3.59a7.13 7.13 0 0 1-1.51-1.16 6.39 6.39 0 0 1-1.94-4.69m3.33 8.29h21.18L39 29.37l-5.43 8.83-2.11-3.48-5.19 8.43M25.72 31a5.21 5.21 0 0 0-1.59 3.82 5.28 5.28 0 0 0 1.59 3.88 5.65 5.65 0 0 0 1.3 1l4.46-7.24L33.57 36 35 33.71a5 5 0 0 0-1.51-2.64 5.35 5.35 0 0 0-7.73 0Z" fill="#fff">
                        </path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
              <div>
                <div className='upload sec_outerDiv' >
                  <Link to="/upload-designnext">
                    <div className="placeholder full-image">
                      <span className="hint">
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72"
                        className="svg-icon icon image-placeholder"><title>0101WF_pixel-spot-illustrations</title>
                        <path d="M6.45 16.88v38.24h59.1V16.88H6.45M7.61 54V18h56.78v36H7.61m17.27-23.76a6.33 6.33 0 0 0-1.94 4.63 6.39 6.39 0 0 0 1.94 4.69 7.13 7.13 0 0 0 1.51 1.16l-2.2 3.59H49.5L39 27.16l-3.19 5.21a7.48 7.48 0 0 0-1.48-2.14 6.3 6.3 0 0 0-4.63-2 6.43 6.43 0 0 0-4.75 2m.81.81a5.35 5.35 0 0 1 7.73 0A5 5 0 0 1 35 33.71L33.57 36l-2.11-3.48L27 39.74a5.65 5.65 0 0 1-1.3-1 5.28 5.28 0 0 1-1.59-3.88A5.21 5.21 0 0 1 25.69 31m21.75 12.16H26.27l5.19-8.43 2.11 3.48L39 29.37Z" fill="#b1b9c2"></path><path d="M7.61 18v36h56.78V18H7.61m15.33 16.87a6.33 6.33 0 0 1 1.94-4.63 6.43 6.43 0 0 1 4.75-2 6.3 6.3 0 0 1 4.63 2 7.48 7.48 0 0 1 1.48 2.14L39 27.16l10.5 17.15H24.18l2.2-3.59a7.13 7.13 0 0 1-1.51-1.16 6.39 6.39 0 0 1-1.94-4.69m3.33 8.29h21.18L39 29.37l-5.43 8.83-2.11-3.48-5.19 8.43M25.72 31a5.21 5.21 0 0 0-1.59 3.82 5.28 5.28 0 0 0 1.59 3.88 5.65 5.65 0 0 0 1.3 1l4.46-7.24L33.57 36 35 33.71a5 5 0 0 0-1.51-2.64 5.35 5.35 0 0 0-7.73 0Z" fill="#fff">
                        </path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
              {/* <div>
                <h3>3</h3>
              </div>
              <div>
                <h3>4</h3>
              </div>
              <div>
                <h3>5</h3>
              </div>
              <div>
                <h3>6</h3>
              </div> */}
            </Slider>
          </div>

          <div>
            <Link to="/upload-designnext">
              <button >
                Upload a Complete Design
              </button>
            </Link>
          </div>



        </Row>


      </Container>






    </>
  )
}
