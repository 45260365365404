import React, { useEffect,memo } from 'react'
import Slider from 'react-slick';
import Canvascomponent from './Canvascomponent';
 


const Canvas=(props)=> {

 



  useEffect(()=>{

  if(props.productData?.side>0 && props.canvasList.length==0) {
      
    for(var i=0; i<props.productData?.side ;i++){
      props.canvasList.push('canvas'+i)
    }


  }


  },[])


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

  return (
    <>
      <Slider {...settings}>

    
{props.canvasList.map((c,i)=>{
    return  <div>
           
           <Canvascomponent id={c} key={i} count={i} width={props.productData?.width} height={props.productData?.height}/>
      </div>
 })}     
    </Slider>
    </>
    
  )
}


export default memo(Canvas)