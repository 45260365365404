import logo from './logo.svg';
import './App.css';
import React, {useState , useRef, useEffect } from 'react'
import './assets/css/style.css'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import FirstPage from './modules/FirstPage';
import MainComoponent from './modules/MainComoponent';
import UploadDesign from './modules/UploadDesign';
import Review from './modules/Review';
import axios from 'axios';


function App() {

  const [productData, setproductData]=useState();
  const canvasList =useRef([]) 
   const [url , setUrl]=useState('https://proofingtool.developmentstagingserver.com/');
   const [api , setApi]=useState('https://proofingtool.developmentstagingserver.com/wp-json/proofing-tool/v1/getProductData'); 

  //const [url , setUrl]=useState('https://wtp10.developmentstagingserver.com/');
  //const [api , setApi]=useState('https://wtp10.developmentstagingserver.com/wp-json/proofing-tool/v1/getProductData');
  const getProductData=async ()=>{

    let product_id = document.querySelector("meta[name='product_id']").getAttribute("content");
    let product_key = document.querySelector("meta[name='product_key']").getAttribute("content");

    let data=await axios.post(api,{product_id:product_id,product_key:product_key}).then(
     res=>{
       console.log(res)
       setproductData(res?.data?.productData);
       if(res?.data?.productData?.side){
        for(var i=0; i<res?.data?.productData?.side ;i++){
          canvasList.current.push('canvas'+i)
        }
        //console.log(canvasList.current);
       } 
     }
    )
}


  useEffect(()=>{
    getProductData()
    
    return()=>{
       
    }
  },[])

  
  return (
   <>
   {/* <MainComoponent/> */}

   <BrowserRouter>
    <Routes>
      <Route path="/" element={<FirstPage productData={productData} />}/>
      <Route path="/upload-design" element={<MainComoponent productData={productData}/>}/> 
      <Route path="/upload-designnext" element={<UploadDesign productData={productData} canvasList={canvasList.current}/>}/> 
      <Route path="/review-designnext" element={<Review/>} productData={productData}/> 
      
    </Routes>
  </BrowserRouter>
   </>
  );
}

export default App;
